<template>
  <div class="coring_dashboard pt-4 pl-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          :to="{ name: 'project', params: { id: campaign.workspace.id } }"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(campaign.name) }}</h1>
          <i class="m_subtitle">
            Gérer la campagne - {{ cores.length }} carottes
          </i>
        </div>
      </div>
      <div class="mt-2">
        <MediumButtonSlot
          class="mr-2"
          v-if="
            campaign.status === 'sent_for_analysis' ||
            campaign.status === 'results_obtained' ||
            campaign.status === 'status_archived'
          "
          @click="openGenerateLinkModale()"
          >Générer un lien public</MediumButtonSlot
        >
        <HighButtonSlot
          class="mr-5"
          :_to="{ name: 'coring-campaign-map', params: { id: campaign.id } }"
        >
          <v-icon left> mdi-map-outline </v-icon>
          Accéder à la carte
        </HighButtonSlot>
      </div>
    </div>
    <div class="scrollable_container mr-2 mb-3">
      <v-textarea
        class="mt-2 px-3 pb-6"
        label="Commentaire global"
        outlined
        min-height="100px"
        color="grey"
        :disabled="!campaign.globalComment && isCommentDisable"
        :clearable="!isCommentDisable"
        :class="[
          isCommentDisable
            ? globalCommentInactiveClass
            : globalCommentActiveClass,
        ]"
        hide-details
        auto-grow
        v-model="campaign.globalComment"
        :readonly="isCommentDisable"
      >
        <template slot="append">
          <div
            class="edit_button"
            v-if="$hasRight('campaigns.addCommentsToDashboard')"
          >
            <v-icon v-if="isCommentDisable" @click="enableModifyComment"
              >mdi-pen</v-icon
            >
            <v-icon v-if="!isCommentDisable" @click="modifyComment"
              >mdi-check-outline</v-icon
            >
          </div>
        </template>
      </v-textarea>

      <v-row class="mx-0">
        <v-col cols="4" class="py-2" v-if="hasAsbestos">
          <v-card outlined>
            <v-card-title class="justify-center">
              Graphique Amiante
            </v-card-title>
            <v-card-text class="justify-center">
              <div ref="asbestosPieChart" class="graph">
                <v-progress-circular
                  class="Loading"
                  :size="300"
                  :width="10"
                  color="#2c0703"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" class="py-2" v-if="hasPah">
          <v-card outlined>
            <v-card-title class="justify-center"> Graphique HAP </v-card-title>
            <v-card-text class="justify-center">
              <div ref="pahPieChart" class="graph">
                <v-progress-circular
                  class="Loading"
                  :size="300"
                  :width="10"
                  color="#2c0703"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" class="py-2" v-if="hasTh">
          <v-card outlined>
            <v-card-title class="justify-center"> Graphique HCT </v-card-title>
            <v-card-text class="justify-center">
              <div ref="thPieChart" class="graph">
                <v-progress-circular
                  class="Loading"
                  :size="300"
                  :width="10"
                  color="#2c0703"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center filters mx-0">
        <v-col>
          <v-card flat class="grey lighten-4 pa-8 center">
            <v-card flat color="transparent">
              <v-row class="d-flex align-center justify-space mx-8">
                <v-col class="flex-grow-1">
                  <v-switch
                    v-if="hasAsbestos"
                    v-model="asbestosFilter"
                    label="Filtrer les couches amiantées"
                  >
                  </v-switch>
                </v-col>
                <v-col class="flex-grow-1">
                  <v-switch
                    v-if="hasPah"
                    v-model="pahFilter"
                    label="Filtrer les couches HAP > 500"
                  >
                  </v-switch>
                </v-col>
                <v-col class="flex-grow-1">
                  <v-switch
                    v-if="hasTh"
                    v-model="thFilter"
                    label="Filtrer les couches HCT"
                  >
                  </v-switch>
                </v-col>
                <v-col class="d-flex flex-row-reverse flex-grow-1">
                  <MediumButtonSlot
                    v-if="
                      (hasPah || hasTh || hasAsbestos) &&
                      (asbestosFilter || pahFilter || thFilter)
                    "
                    class="p-2"
                    @click="removeFilters"
                  >
                    Réinitialiser les filtres
                  </MediumButtonSlot>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <AsbestosTable
        v-if="hasAsbestos"
        class="mt-5 px-3"
        :asbestosTableData="filteredData"
      ></AsbestosTable>

      <PahTable v-if="hasPah" :pahTableData="filteredData" class="mt-5 px-3">
      </PahTable>

      <ThTable :hctTableData="filteredData" v-if="hasTh" class="mt-5 px-3">
      </ThTable>
    </div>
    <Modale
      v-if="isGenerateLinkModaleOpen"
      title="Partager la campagne"
      @close="closeGenerateLinkModale"
      @validate="closeGenerateLinkModale"
    >
      <div class="generateLinkInput">
        <v-col md="8">
          <input ref="generatedLink" outlined disabled :value="url" />
        </v-col>
        <MediumButtonSlot @click="generateShareLink()"
          >Copier le lien</MediumButtonSlot
        >
      </div>
      <template slot="actions">
        <LowButtonSlot @click="closeGenerateLinkModale()">Fermer</LowButtonSlot>
      </template>
    </Modale>
  </div>
</template>

<script>
import amchartUtils from "../../utils/amcharts.utils.js";
import AsbestosTable from "../../components/coring/dashboard/Asbestos.table";
import PahTable from "../../components/coring/dashboard/Hap.table";
import ThTable from "../../components/coring/dashboard/Hct.table";
import areiaUtils from "../../utils/areia.utils";

export default {
  name: "CoringCampaignDashboard",
  components: {
    ThTable,
    PahTable,
    AsbestosTable,
  },
  data() {
    return {
      url:
        window.location.origin +
        "/share-link?token=eajklsdDZSdgfHsJjfEYhfe4lsdDhdgf5hSEafAlsdDhdgfk",
      isGenerateLinkModaleOpen: false,
      campaign: {
        workspace: {
          id: 1,
        },
        globalComment: "",
      },
      tableData: [],
      asbestosAllData: [],
      pahAllData: [],
      thAllData: [],
      thPieChart: [],
      asbestosPieChart: [],
      pahPieChart: [],
      thChart: [],
      asbestosChart: [],
      pahChart: [],
      cores: [],
      asbestosFilter: false,
      thFilter: false,
      pahFilter: false,
      isCommentDisable: true,
      campaignPrestations: [],
      hasAsbestos: false,
      hasPah: false,
      hasTh: false,
      globalCommentActiveClass: "cursor_text",
      globalCommentInactiveClass: "cursor_default",
      composeList: [
        "Naphtalène",
        "Acénaphtylène",
        "Acénaphtène",
        "Fluorène",
        "Phénanthrène",
        "Anthracène",
        "Fluoranthène",
        "Pyrène",
        "Benzo(a)anthracène",
        "Chrysène",
        "Benzo(b)fluoranthène",
        "Benzo(k)fluoranthène",
        "Benzo(a)pyrène",
        "Indéno(1,2,3-cd)pyrène",
        "Dibenzo(a,h)anthracène",
        "Benzo(g,h,i)pérylène",
      ],
    };
  },
  created() {
    this.asbestosPieChartUnwatch = this.$watch(
      "asbestosPieChart",
      () => {
        this.showAsbestosGraph();
        this.asbestosPieChartUnwatch();
      },
      {
        deep: true,
      }
    );
    this.pahPieChartUnwatch = this.$watch(
      "pahPieChart",
      () => {
        this.showPahGraph();
        this.pahPieChartUnwatch();
      },
      {
        deep: true,
      }
    );
    this.thPieChartUnwatch = this.$watch(
      "thPieChart",
      () => {
        this.showThGraph();
        this.thPieChartUnwatch();
      },
      {
        deep: true,
      }
    );
  },
  async mounted() {
    amchartUtils.disposeAll();
    this.fetchCampaign();
    this.fetchCores();
    this.fetchTableData();
    await this.fetchCampaignPrestations();
    this.fetchGraphs();
  },
  computed: {
    filteredData() {
      let finalData = this.tableData;

      if (true === this.asbestosFilter)
        try {
          finalData = finalData.filter(
            (l) => true === l.results.ASBESTOS.computed.positive
          );
        } catch (e) {
          finalData = [];
        }

      if (true === this.pahFilter)
        try {
          finalData = finalData.filter(
            (l) => 500 < l.results.PAH.computed.PAHSumWithLQ.value
          );
        } catch (e) {
          finalData = [];
        }

      if (true === this.thFilter)
        try {
          finalData = finalData.filter(
            (l) => 500 < l.results.TH.computed.total.value
          );
        } catch (e) {
          finalData = [];
        }

      return finalData;
    },
  },
  methods: {
    async generateShareLink() {
      const guestUser = await this.$api.campaigns.inviteGuestUser(
        this.$route.params.id
      );
      this.url =
        window.location.origin + "/share-link?token=" + guestUser.token;
      this.copyTextToClipboard();
    },
    fallbackCopyTextToClipboard() {
      this.$refs.generatedLink.focus();
      this.$refs.generatedLink.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("unable to copy", err);
      }
    },
    copyTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard();
        return;
      }
      navigator.clipboard.writeText(this.url);
      this.$toast("success", "Lien copié dans le presse papier !");
    },
    openGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = true;
    },
    closeGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = false;
    },
    updateComponents() {
      this.hasAsbestos = areiaUtils.hasAsbestos(this.campaignPrestations);
      this.hasPah = areiaUtils.hasPAH(this.campaignPrestations);
      this.hasTh = areiaUtils.hasTH(this.campaignPrestations);
    },
    async fetchCampaignPrestations() {
      this.campaignPrestations = await this.$api.campaigns.getPrestations(
        this.$route.params.id
      );
      this.updateComponents();
    },
    async fetchCampaign() {
      this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
    },
    async fetchTableData() {
      this.tableData = await this.$api.campaigns.getData(
        this.$route.params.id,
        "table"
      );
    },
    async fetchData(type) {
      try {
        this[type + "PieChart"] = await this.$api.campaigns.getData(
          this.$route.params.id,
          "pie",
          type.toUpperCase()
        );
        this[type + "AllData"] = await this.$api.campaigns.getData(
          this.$route.params.id,
          "table",
          type.toUpperCase()
        );
      } catch (e) {
        this[`has${this.$cap(type)}`] = false;
      }
    },
    fetchGraphs() {
      if (this.hasAsbestos) this.fetchData("asbestos");
      if (this.hasPah) this.fetchData("pah");
      if (this.hasTh) this.fetchData("th");
    },
    filterAbsestos() {
      let oldData = this.asbestosChart;

      this.asbestosChart = [];
      this.asbestosChart = oldData.filter(
        (layer) => layer.results.computed.ASBESTOS.positive
      );
    },
    filterPAH() {
      let oldData = this.pahChart;
      this.pahChart = [];
      let results = [];
      for (let a = 0; a < oldData.length; a++) {
        // Pour chacun de mes 12 layers

        results.push(oldData[a].results.raw.RESULTAT_COMPOSE); // Récupération des 16 composés de chaque layers
        loop1: for (let b = 0; b < results.length; b++) {
          // Pour chacun des 16 composés de chaque layers
          for (let c = 0; c < this.composeList.length; c++) {
            // Pour chaques composés
            let value = 0;
            if (results[a][this.composeList[c]] !== "< 0,50") {
              // Si différent de bon (string)
              if (!results[a][this.composeList[c]].toString().includes(">")) {
                // Vérification int ou string
                value = Number(
                  results[a][this.composeList[c]].toString().replace(",", ".")
                ); // Transformation de value en number
                if (value > 0.5) {
                  // Deuxième vérification différent de bon (int) = on remet dans le tableau et on stop le for de chaques composés
                  this.pahChart.push(oldData[a]);
                  break loop1;
                }
              } else {
                // Si pas un int, alors c'est un string > 0.50 = On remet dans le tableau et on stop le for de chaques composés
                this.pahChart.push(oldData[a]);
                break loop1;
              }
            }
          }
        }
      }
    },
    filterTH() {
      let oldData = this.thChart;
      this.thChart = [];
      oldData.forEach((e) => {
        if (e.results.raw.TOTAUX_C10_C40 > 500) {
          this.thChart.push(e);
        }
      });
    },
    filterPahAndTh() {
      this.pahChart = this.pahAllData;
      if (this.pahFilter) this.filterPAH();
      this.pahChart = this.pahChart.filter((e) => {
        return (
          this.asbestosChart.filter((ele) => ele.coreLayerId === e.coreLayerId)
            .length > 0
        );
      });
      this.thChart = this.thAllData;
      if (this.thFilter) this.filterTH();
      this.thChart = this.thChart.filter(
        (e) =>
          this.asbestosChart.filter((ele) => ele.coreLayerId === e.coreLayerId)
            .length > 0
      );
    },
    showPahGraph() {
      amchartUtils.createPieChart(
        this.$refs.pahPieChart,
        this.pahPieChart.data,
        this.pahPieChart.title
      );
    },
    showThGraph() {
      amchartUtils.createPieChart(
        this.$refs.thPieChart,
        this.thPieChart.data,
        this.thPieChart.title
      );
    },
    showAsbestosGraph() {
      amchartUtils.createPieChart(
        this.$refs.asbestosPieChart,
        this.asbestosPieChart.data,
        this.asbestosPieChart.title
      );
    },
    async fetchCores() {
      this.cores = await this.$api.cores.findByCampaign(this.$route.params.id);
    },
    removeFilters() {
      this.pahFilter = false;
      this.thFilter = false;
      this.asbestosFilter = false;
    },
    enableModifyComment() {
      this.isCommentDisable = false;
    },
    async modifyComment() {
      await this.$api.campaigns.editComment(
        this.$route.params.id,
        this.campaign.globalComment
      );
      this.isCommentDisable = true;
    },
  },
};
</script>

<style lang="scss">
.coring_dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;

  .graph {
    min-height: 300px;
  }

  .Loading {
    margin-left: 60px;
  }
  .filters {
    .v-card {
      width: 100%;
    }
  }
  .edit_button {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
  .cursor_text {
    textarea {
      cursor: text;
    }
  }
  .cursor_default {
    textarea {
      cursor: default !important;
    }
  }
  .generateLinkInput {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & input {
      width: 100%;
      border: 1px solid gray;
      padding: 5px;
      border-radius: 5px;
    }
  }
}
</style>
