<template>
  <div id="campaign" class="pa-4">
    <v-card class="d-flex" flat tile>
      <v-btn
        class="mt-5 ml-2 mr-2 m_brown"
        icon
        :to="{ name: 'project', params: { id: campaign.workspace.id } }"
      >
        <v-icon large>mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-card class="pa-2" flat>
        <h2>{{ $cap(campaign.name) }}</h2>
        <p class="m_subtitle">Gérer la campagne</p>
      </v-card>
    </v-card>
    <v-row class="scrollable_container ma-0 pa-1">
      <v-col class="col-6 pl-1">
        <v-card outlined class="campaign_card mb-5">
          <v-card-title>
            <b>Informations de la campagne</b>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>

          <v-card-text>
            <v-card flat>
              <v-text-field
                class="mt-1 mb-8"
                label="Nom de la campagne"
                :value="campaign.name"
                v-model="form.name"
                outlined
                clearable
                hide-details
              >
              </v-text-field>
              <v-textarea
                class="mt-1 m_textarea"
                label="Description de la campagne"
                :value="campaign.description"
                v-model="form.description"
                outlined
                :counter="500"
                :rules="[v => v.length <= 500 || '500 caractères max']"
                auto-grow
                clearable
              >
              </v-textarea>
              <v-select
                v-if="campaign.type === 'auscultation'"
                :disabled="!$hasRight('campaigns.editCampaignBtn')"
                :items="operatingModes"
                item-text="name"
                item-value="value"
                label="Mode opératoire de relevé de dégradations"
                outlined
                clearable
                v-model="form.operatingMode"
                dense
              ></v-select>
              <v-text-field
                :disabled="!$hasRight('campaigns.editCampaignBtn')"
                v-if="
                  campaign.type === 'auscultation' &&
                  form.operatingMode === 'not_standard'
                "
                label="Commentaire sur le mode opératoire"
                v-model="form.operatingModeComment"
              >
              </v-text-field>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <HighButtonSlot
              :_loading="loadingButton.informations"
              class="mb-3 mr-2"
              @click="updateCampaignInformations()"
            >
              Sauvegarder les modifications
            </HighButtonSlot>
          </v-card-actions>
        </v-card>
        <v-card outlined class="campaign_card">
          <v-card-title>
            <b>Utilisateurs</b>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>

          <v-card-text>
            <v-select
              label="Client principal"
              v-model="userForm.mainCustomerId"
              :items="customers"
              item-text="name"
              item-value="id"
              clearable
              hide-details
              class="mt-1 mb-7"
              outlined
              :loading="loading"
            >
            </v-select>
            <v-select
              label="Ingénieur"
              v-model="userForm.engineerId"
              :items="engineers"
              item-text="name"
              item-value="id"
              hide-details
              class="mt-1 mb-7"
              outlined
              :loading="loading"
              clearable
            >
            </v-select>
            <v-select
              label="Chargé d'étude"
              v-model="userForm.studyManagerId"
              :items="studyManagers"
              item-text="name"
              item-value="id"
              clearable
              hide-details
              class="mt-1"
              outlined
              :loading="loading"
            >
            </v-select>
            <v-autocomplete
              v-if="campaign.type === 'coring'"
              label="Technicien"
              v-model="userForm.technicianIds"
              :items="technicians"
              item-text="name"
              item-value="id"
              hide-details
              class="mt-1 mt-7"
              outlined
              clearable
              :loading="loading"
              multiple
              small-chips
            >
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <HighButtonSlot
              :_loading="loadingButton.users"
              class="mb-3 mr-2"
              @click="updateCampaignUsers()"
            >
              Sauvegarder les modifications
            </HighButtonSlot>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="col-6 pr-1">
        <v-card outlined class="campaign_card">
          <v-card-title>
            <b>Utilisateurs secondaires</b>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle>
            Vous pouvez ajouter ou retirer des clients secondaires en lecture
            seule à cette campagne
          </v-card-subtitle>

          <v-card-text>
            <v-row v-if="secondaryCustomers.length > 0">
              <v-col class="col-8 text-center"> Nom du client </v-col>
              <v-col class="col-2"> Lecture seule </v-col>
            </v-row>
            <v-list>
              <v-row
                v-for="(secondaryCustomer, index) in secondaryCustomers"
                :key="index"
              >
                <v-col class="col-8">
                  <v-list-item>
                    <v-autocomplete
                      label="Client secondaire"
                      v-model="secondaryCustomer.customer.id"
                      :items="allSecondaryCustomers"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                    >
                    </v-autocomplete>
                  </v-list-item>
                </v-col>
                <v-col class="col-2">
                  <v-list-item>
                    <v-switch
                      color="#2c0703"
                      :input-value="secondaryCustomer.readOnly"
                    >
                    </v-switch>
                  </v-list-item>
                </v-col>
                <v-col class="col-2 mt-2">
                  <v-list-item>
                    <v-icon large> mdi-close </v-icon>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              v-if="
                secondaryCustomers[secondaryCustomers.length - 1] ==
                  undefined ||
                (secondaryCustomers[secondaryCustomers.length - 1].customer !=
                  undefined &&
                  secondaryCustomers[secondaryCustomers.length - 1].customer
                    .id !== '')
              "
              class="mb-4"
              @click="addSecondaryCustomersForm()"
              elevation="0"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <high-button-slot
              class="mb-4"
              @click="openCreateUserModale()"
              elevation="0"
            >
              créer utilisateur
            </high-button-slot>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <CreateUserModale
      @closePopin="isUserCreateModaleOpen = false"
      :organization="campaign.workspace.agency.organization"
      v-if="isUserCreateModaleOpen"
    />
  </div>
</template>

<script>
import CreateUserModale from "@/components/entities/user/CreateUser.modal";

export default {
  components: { CreateUserModale },
  name: "ManageCampaign.vue",
  data() {
    return {
      campaign: {
        workspace: {
          id: ""
        },
        type: ""
      },
      operatingModes: [
        { name: "M1", value: "M1" },
        { name: "M2", value: "M2" },
        { name: "M3", value: "M3" },
        { name: "M4", value: "M4" },
        { name: "M6", value: "M6" },
        { name: "M7", value: "M7" },
        { name: "M2+", value: "M2+" },
        { name: "M3+", value: "M3+" },
        { name: "Non standard", value: "not_standard" }
      ],
      campaignTypes: [],
      campaignUsers: [],
      customers: [],
      engineers: [],
      studyManagers: [],
      technicians: [],
      engineer: "",
      studyManager: "",
      technician: "",
      customer: "",
      loading: true,
      secondaryCustomers: [],
      allSecondaryCustomers: [],
      form: {
        name: "",
        description: "",
        operatingMode: "",
        operatingModeComment: ""
      },
      loadingButton: {
        users: false,
        informations: false
      },
      userForm: {
        engineerId: this.engineer,
        studyManagerId: this.studyManager,
        technicianIds: [],
        mainCustomerId: this.customer
      },
      isUserCreateModaleOpen: false
    };
  },
  mounted() {
    this.fetchCampaign();
  },
  methods: {
    openCreateUserModale() {
      this.isUserCreateModaleOpen = true;
    },
    fetchCampaign() {
      this.$api.campaigns.findById(this.$route.params.id).then(campaign => {
        this.campaign = campaign;

        this.form.name = this.campaign.name;
        this.form.description = this.campaign.description;
        this.form.operatingMode = this.campaign.operatingMode;
        this.form.operatingModeComment = this.campaign.operatingModeComment;
        this.fetchCampaignUsers();
        this.fetchAllUsers();
      });
    },
    fetchCampaignUsers() {
      this.$api.campaigns
        .getUserCampaigns(this.campaign.id)
        .then(campaignUsers => {
          this.campaignUsers = campaignUsers;

          this.campaignUsers.forEach(e => {
            if (e.customer.job === "engineer") {
              this.engineer = e.customer.id;
            } else if (e.customer.job === "study_manager") {
              this.studyManager = e.customer.id;
            } else if (e.customer.job === "technician") {
              this.userForm.technicianIds.push(e.customer.id);
            } else if (e.customer.roles.includes("ROLE_CUSTOMER_MAIN")) {
              this.customer = e.customer.id;
            }
            if (e.customer.roles.includes("ROLE_CUSTOMER_SECONDARY")) {
              this.secondaryCustomers.push(e);
            }
          });
          this.userForm.engineerId = this.engineer;
          this.userForm.studyManagerId = this.studyManager;
          this.userForm.technicianId = this.technician;
          this.userForm.mainCustomerId = this.customer;
          this.loading = false;
        });
    },
    async fetchAllUsers() {
      const users = await this.$api.users.findAll();
      this.engineers = users
        .filter(user => user.job === "engineer")
        .map(user => this.mapUserInformations(user));

      this.technicians = users
        .filter(user => user.job === "technician")
        .map(user => this.mapUserInformations(user));

      this.studyManagers = users
        .filter(user => user.job === "study_manager")
        .map(user => this.mapUserInformations(user));

      this.customers = users
        .filter(
          user =>
            user.roles.includes("ROLE_CUSTOMER_MAIN") &&
            user.agency.organization.id ===
              this.campaign.workspace.customerAgency.organization.id
        )
        .map(user => this.mapUserInformationsRole(user));

      this.allSecondaryCustomers = users
        .filter(user => user.roles.includes("ROLE_CUSTOMER_SECONDARY"))
        .map(user => this.mapUserInformationsRole(user));
    },
    mapUserInformations(user) {
      return {
        name: user.firstname + " " + user.lastname + " (" + user.email + ")",
        id: user.id,
        job: user.job
      };
    },
    mapUserInformationsRole(user) {
      return {
        name: user.firstname + " " + user.lastname + " (" + user.email + ")",
        id: user.id,
        job: user.role,
        readOnly: user.readOnly
      };
    },
    updateCampaignInformations() {
      this.loadingButton.informations = true;
      this.$api[this.campaign.type + "Campaigns"]
        .edit(this.campaign.id, this.form)
        .then(campaign => {
          this.campaign = campaign;
          this.loadingButton.informations = false;
        });
    },
    updateCampaignUsers() {
      this.loadingButton.users = true;
      this.$api.campaigns
        .editUsers(this.campaign.id, this.userForm)
        .then(() => {
          this.$toast("success", "Utilisateurs mis à jour !");
          this.loadingButton.users = false;
        });
    },
    addSecondaryCustomersForm() {
      this.secondaryCustomers.push({ customer: { name: "", id: "" } });
    }
  }
};
</script>

<style lang="scss" scoped>
#campaign {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .center {
    text-align: center;
  }

  .m_subtitle {
    color: grey;
    font-family: "Open Sans", sans-serif;
  }
}

.campaign_card_advanced {
  margin-top: 20px;
}

.shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
</style>
