const asbestosPrestationsNumbers = [5, 6, 7, 8, 330];

const pahPrestationsNumbers = [21, 23, 325, 326, 26, 114];

const thPrestationsNumbers = [
  32, 33, 29, 40, 39, 36, 47, 46, 43, 293, 294, 300, 301
];

export default {
  hasAsbestos(prestations = []) {
    if (0 === prestations.length) return false;
    let hasAsbestos = false;

    prestations.forEach(prestation => {
      if (asbestosPrestationsNumbers.includes(prestation.number))
        hasAsbestos = true;
    });
    return hasAsbestos;
  },
  hasPAH(prestations = []) {
    if (0 === prestations.length) return false;
    let hasPAH = false;

    prestations.forEach(prestation => {
      if (pahPrestationsNumbers.includes(prestation.number))
        hasPAH = true;
    });
    return hasPAH;
  },
  hasTH(prestations = []) {
    if (0 === prestations.length) return false;
    let hasTH = false;

    prestations.forEach(prestation => {
      if (thPrestationsNumbers.includes(prestation.number))
        hasTH = true;
    });
    return hasTH;
  }
};
