<template>
  <div class="tableContainer">
    <v-expansion-panels class="bordered" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-center">
            <h2 class="mb-2 mt-2">Résultats HCT</h2>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="bordered"
            flat
            :headers="headers"
            :items="hctTableData"
            :items-per-page="-1"
            hide-default-footer
            :sort-by="['coreBusiness', 'coreLayerNumber']"
            :sort-desc="[false, false]"
          >
            <template
              v-slot:[`item.results.TH.computed.total.value`]="{ item }"
            >
              <v-chip :color="getColor(item.results.TH.computed.category)">
                {{ item.results.TH.computed.category }}
              </v-chip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "HctTable",
  props: { hctTableData: { required: true, type: Array } },
  data() {
    return {
      headers: [
        {
          text: "Carotte",
          align: "center",
          sortable: false,
          value: "core.carotte"
        },
        {
          text: "Numéro couche",
          align: "center",
          sortable: false,
          value: "number"
        },
        {
          text: "Matériau",
          align: "center",
          sortable: false,
          value: "materialName"
        },
        {
          text: "Route",
          align: "center",
          sortable: false,
          value: "core.road.name"
        },
        {
          text: "Sens",
          align: "center",
          sortable: false,
          value: "core.direction"
        },
        {
          text: "PR",
          align: "center",
          sortable: false,
          value: "core.landmark"
        },
        {
          text: "Abs",
          align: "center",
          sortable: false,
          value: "core.abscissa"
        },
        {
          text: "Latéralisation",
          align: "center",
          sortable: false,
          value: "core.lateralPosition"
        },
        {
          text: "Totaux C10-C40",
          align: "center",
          sortable: false,
          value: "results.TH.computed.total.value"
        }
      ]
    };
  },
  methods: {
    getColor(item) {
      if ("< 500" === item) return "#69b34c";
      else return "#ff0d0d";
    },
    getText(item) {
      let isLessThan500 = false;
      if (item <= 500) isLessThan500 = true;
      if (isLessThan500) return "< 500";
      else return "> 500";
    }
  }
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
