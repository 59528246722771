<template>
  <div class="tableContainer">
    <v-expansion-panels class="bordered" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-center">
            <h2 class="mb-2 mt-2">Résultats HAP</h2>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="bordered"
            flat
            :headers="headers"
            :items="pahTableData"
            :items-per-page="-1"
            hide-default-footer
            :sort-by="['coreBusiness', 'coreLayerNumber']"
            :sort-desc="[false, false]"
          >
            <template v-slot:[`item.results.PAH.computed.PAHSum`]="{ item }">
              <v-chip
                :class="
                  item.results.PAH.computed.category === '> 1000'
                    ? 'whiteText'
                    : ''
                "
                :color="getInfos(item.results.PAH.computed.category).color"
              >
                {{ item.results.PAH.computed.PAHSum.text }}
              </v-chip>
            </template>
            <template
              v-slot:[`item.results.PAH.computed.PAHSumWithLQ`]="{ item }"
            >
              <v-chip
                :class="
                  item.results.PAH.computed.category === '> 1000'
                    ? 'whiteText'
                    : ''
                "
                :color="getInfos(item.results.PAH.computed.category).color"
              >
                {{ item.results.PAH.computed.PAHSumWithLQ.text }}
              </v-chip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "HapTable",
  props: { pahTableData: { required: true, type: Array } },
  data() {
    return {
      headers: [
        {
          text: "Carotte",
          align: "center",
          sortable: false,
          value: "core.carotte",
          width: "200px"
        },
        {
          text: "Numéro couche",
          align: "center",
          sortable: false,
          value: "number"
        },
        {
          text: "Matériau",
          align: "center",
          sortable: false,
          value: "materialName"
        },
        {
          text: "Route",
          align: "center",
          sortable: false,
          value: "core.road.name"
        },
        {
          text: "Sens",
          align: "center",
          sortable: false,
          value: "core.direction"
        },
        {
          text: "PR",
          align: "center",
          sortable: false,
          value: "core.landmark"
        },
        {
          text: "Abs",
          align: "center",
          sortable: false,
          value: "core.abscissa"
        },
        {
          text: "Latéralisation",
          align: "center",
          sortable: false,
          value: "core.lateralPosition"
        },
        {
          text: "Somme des HAP (excluant LQ)",
          align: "center",
          sortable: false,
          value: "results.PAH.computed.PAHSum",
          width: "150px"
        },
        {
          text: "Somme des HAP (incluant LQ)",
          align: "center",
          sortable: false,
          value: "results.PAH.computed.PAHSumWithLQ",
          width: "150px"
        },
        {
          text: "Naphtalène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Naphtalène"
        },
        {
          text: "Acénaphtylène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Acénaphtylène"
        },
        {
          text: "Acénaphtène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Acénaphtène"
        },
        {
          text: "Fluorène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Fluorène"
        },
        {
          text: "Phénanthrène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Phénanthrène"
        },
        {
          text: "Anthracène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Anthracène"
        },
        {
          text: "Fluoranthène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Fluoranthène"
        },
        {
          text: "Pyrène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Pyrène"
        },
        {
          text: "Benzo(a)anthracène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Benzo(a)anthracène"
        },
        {
          text: "Chrysène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Chrysène"
        },
        {
          text: "Benzo(b)fluoranthène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Benzo(b)fluoranthène"
        },
        {
          text: "Benzo(k)fluoranthène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Benzo(k)fluoranthène"
        },
        {
          text: "Benzo(a)pyrène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Benzo(a)pyrène"
        },
        {
          text: "Indéno(1,2,3-cd)pyrène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Indéno(1,2,3-cd)pyrène"
        },
        {
          text: "Dibenzo(a,h)anthracène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Dibenzo(a,h)anthracène"
        },
        {
          text: "Benzo(g,h,i)pérylène",
          align: "center",
          sortable: false,
          value: "results.PAH.raw.RESULTAT_COMPOSE.Benzo(g,h,i)pérylène"
        }
      ]
    };
  },
  methods: {
    getInfos(category) {
      switch (category) {
        case "[0; 50]":
          return {
            color: "#69b34c",
            text: "[0; 50]"
          };
        case "]50; 500]":
          return {
            color: "#ff8e15",
            text: "]50; 500]"
          };
        case "]500; 1000]":
          return {
            color: "#ff0d0d",
            text: "]500; 1000]"
          };
        case "> 1000":
          return {
            color: "#000",
            text: "> 1000"
          };
      }
    },
    getColor(item) {
      let value = item.replace("< ", "");
      value = Number(value.replace(",", "."));
      if (value <= 50) return "#69b34c";
      if (value <= 500) return "#ff8e15";

      return value < 1000 ? "#ff0d0d" : "#000000";
    }
  }
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}

.whiteText {
  color: white !important;
}
</style>
